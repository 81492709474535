export default {
  TitleError() {
    if (!this.$v.template.Title.$anyError) return null;
    else if (!this.$v.template.Title.required) return "required_field";
    else return null;
  },
  DescriptionError() {
    if (!this.$v.template.Description.$anyError) return null;
    else if (!this.$v.template.Description.required) return "required_field";
    else if (!this.$v.template.Description.maxLength) return "max_length";
    else return null;
  },
  ContentError() {
    if (!this.$v.template.Content.$anyError) return null;
    else if (!this.$v.template.Content.required) return "required_field";
    else if (!this.$v.template.Content.maxLength) return "max_length";
    else return null;
  },
  //endregion
};
