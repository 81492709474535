import { render, staticRenderFns } from "./TemplateList.html?vue&type=template&id=227f177b&scoped=true&external"
import script from "./TemplateList.js?vue&type=script&lang=js&external"
export * from "./TemplateList.js?vue&type=script&lang=js&external"
import style0 from "./TemplateList.scss?vue&type=style&index=0&id=227f177b&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "227f177b",
  null
  
)

/* custom blocks */
import block0 from "../locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2FSettings%2FTemplates%2FSms%2FTemplateList%2Findex.vue&scoped=true&external"
if (typeof block0 === 'function') block0(component)

export default component.exports