import Validations from './Validations';
import Errors from './Errors';

import SMSTService from '@/services/sms.template.service';

const smsTService = new SMSTService();

export default {
  name: 'template-list',
  components: {},
  props: [],
  data() {
    return {
      templates: [],
      category: '',
      template: {
        Category: "",
        Title: "",
        Description: "",
        Content: "",
        Shared: false,
      },
      templateSelected: {},
      selectedToRemove: null,
      modal: {
        create: false,
        update: false,
        remove: false
      },
      perPage: 25,
      totalRows: 0,
      currentPage: 1

    }
  },
  validations: Validations,
  computed: {
    ...Errors,
    highlightedText() {
      return this.highlightMessages(this.template.Content);
    }
  },
  async mounted() {
    const {id} = this.$route.params;
    this.category = id;
    await this.list();
  },
  methods: {
    async create() {
      await this.$v.$touch();
      if (this.$v.$invalid) return;

      this.template.Category = this.category;
      const res = await smsTService.createTemplate(this.template);
      if (res && !res.error) {
        this.closeModal();
        await this.list();
      }
    },

    async update() {
      const res = await smsTService.updateTemplate(this.templateSelected);
      if (res && !res.error) {
        this.closeModal();
        await this.list();
      }
    },

    async remove() {
      //TODO: Remove Template
      const res = await smsTService.removeTemplate(this.selectedToRemove);
      if (res && !res.error) {
        this.closeModal();
        await this.list();
      }
    },

    async list() {
      const skip = this.perPage * (this.currentPage - 1);

      const res = await smsTService.list(this.perPage, skip, this.category);
      if (res && !res.error) {
        this.templates = res.data.templates;
        this.totalRows = res.data.count;
      }
    },

    closeModal() {
      this.modal = {
        create: false,
        update: false,
        remove: false,
      }
      this.template = {
        Category: "",
        Title: "",
        Description: "",
        Content: "",
        Shared: false,
      }
      this.templateSelected = {}
      this.selectedForRemove = null
    },

    selectTemplate(template) {
      this.templateSelected = template;
      this.modal.update = true;
    },

    selectTemplateToRemove(id) {
      this.selectedToRemove = id;
      this.modal.remove = true;
    },

    insertVariable(val) {
      const replace = `{{${val}}}`;
      const textarea = this.$refs.textarea;
      const sentence = textarea.value;
      const pos = textarea.selectionStart ?? 0;
      const before = sentence.substring(0, pos);
      const after = sentence.substring(pos);

      this.template.Content = before + replace + after;

      this.$nextTick().then(() => {
        textarea.focus();
        textarea.selectionStart = pos + replace.length;
        textarea.selectionEnd = pos + replace.length;
      });
    },

    highlightMessages(text) {
      const style = `background-color:#ea4c89;border-radius:.3rem;color:white;`
      const regex = /{{(.*?)}}/g;
      return text.replace(regex, `<span style="${style}" class="highlight">{{$1}}</span>`);
    },

    onInput(event) {
      this.template.Content = event.target.value;
    },

    onScroll() {
      this.$refs.highlightLayer.scrollTop = this.$refs.textarea.scrollTop;
    }
  }
}


